import * as React from "react";
import cloud_logo from "../../svg/Services/cloud-logo.svg"

function SvgComponent(props) {
  return (
    <img src={cloud_logo} width={48.336} height={40.531} alt="mobile icon" />
  );
}

export default SvgComponent;
